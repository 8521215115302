<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    en: {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
  },
  components: {
    Layout,
    VclList,
    Calendar
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      balance: 'R$ 0,00',

      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "date", label: "Data" },
        { key: "type", label: "Tipo" },
        { key: "description", label: "Descrição" },
        { key: "value", label: "Valor" },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      periodMonth: new Date(),

      br: {
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      }
    };
  },
  methods: {
    getSaldo() {
      api.get("wallet/balance/network").then((response) => {
        if (response.data.status == "success") {
          this.balance = this.$options.filters.currency(response.data.balance);
        }
      });
    },
    getPeriod() {
      if (this.periodMonth) {
        const date = [
          new Date(this.periodMonth).getFullYear(),
          new Date(this.periodMonth).getMonth() + 1
        ];

        return date.join('-');
      } else {
        const date = [
          new Date().getFullYear(),
          new Date().getMonth() + 1
        ];

        return date.join('-');
      }
    },
    getExtrato() {
      this.loading = true;
      this.errored = false;
      this.items = null;

      api
        .get("wallet/extract/network?date=" + this.getPeriod())
        .then((response) => {
          if (response.data.status == "success") {
            this.total = response.data.total;
            this.items = response.data.list;
          } else {
            this.total = 0;
            this.items = [];
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSaldo();
    this.getExtrato();
  },
  watch: {
    'periodMonth': function () {
      this.getExtrato();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Financeiro</h3>
        <p>Consulte suas transações por período.</p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ this.balance }}</h6>
        <h6 class="m-0 text-secondary">Saldo disponível</h6>
      </div>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Filtrar período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-group>
          <Calendar v-model="periodMonth" view="month" dateFormat="yy-mm-dd" inline :locale="br" style="width: 250px;">
          </Calendar>
        </b-dropdown-group>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item to="/wallet/withdrawal">Sacar</b-dropdown-item>
        <b-dropdown-item to="/wallet/withdrawal/history">Histórico de saque</b-dropdown-item>
        <b-dropdown-item to="/wallet/transfer">Transferir</b-dropdown-item>
        <b-dropdown-item to="/wallet/pay">Pagar</b-dropdown-item>
      </b-dropdown>
    </div>

    <div id="extrato" class="card">
      <div class="card-body">
        <div class="error text-center min-vh-50" v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="empty text-center min-vh-50">
          <strong>Nenhuma movimentação no período.</strong>
        </div>
        <div v-else class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" responsive>
            <template #cell(value)="row">
              {{ row.item.value | currency }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>
  </Layout>
</template>
